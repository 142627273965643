@import '../../Style/Variables.css';

.productSliderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1300px;
    box-sizing: border-box;
    /* background-color: lightcoral; */
}

.productSliderArea {
    max-width: 100vw;
}

.productTabArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    overflow-x: auto;
    max-width: 100vw;
}
/* .productTabArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
} */

.sliderRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.productSliderTab {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: var(--mediumBorderRadius);
    cursor: pointer;
    gap: 4px;
    padding: 4px 8px;
}

.productSliderTitle {
    display: flex;
    font-size: 18px;
    font-weight: bold;
}
.productSliderTabImg {
    max-width: 42px;
    max-height: 42px;
}
  
.activeTab {
background-color: white;
}

.activeTabTitle {
display: flex !important;
}

@media (max-width: 1280px) {
    .productSliderContainer {
        width: 100vw;
    }
    .productTabArea {
        justify-content: start;
    }
}

@media (max-width: 800px) {
    .productSliderContainer {
        padding: 0 10px 10px 10px;
        
    }
    .productTabArea {
        margin-bottom: 6px;
        /* min-width: 100vw; */
    }
    .productSliderTab {
        flex-direction: column;
    }
    .productSliderTitle {
        /* display: none; */
        text-align: center;
        font-size: 12px;
    }
    .productSliderTabImg {
        max-width: 32px;
        max-height: 32px;
    }
}